<template>
  <b-modal
      id="modal-create-value"
      ref="modal-create-value"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.createPersonalizationValue.title') }}</h3>
    </template>

    <template #default>
      <div>
        <div class="mt-4 mx-5 mb-3">
          <label for="value-name">
            {{ $t('modals.createPersonalizationValue.name') }}
          </label>
          <b-form-input
              id="value-name"
              v-model="name"
              autofocus
              debounce="500"
              @keyup.enter="enterPressed"
              @update="nameUpdated"
          >
          </b-form-input>
        </div>
        <div class="mx-5 mb-4">
          <label for="value-id">
            {{ $t('modals.createPersonalizationValue.id') }}
          </label>
          <b-form-input
              id="value-id"
              v-model="id"
              debounce="500"
              @input="idChangedByUser"
              @keyup.enter="enterPressed"
          >
          </b-form-input>
        </div>
        <div class="mx-5 mb-4">
          <div class="radio-tile-group">
            <div class="input-container">
              <input
                  id="TextField"
                  class="radio-button"
                  type="radio"
                  name="radio"
                  @change="onChange($event)"
              />
              <div class="radio-tile">
                <div class="icon-wrapper">
                  <b-icon icon="card-text"></b-icon>
                </div>
                <div class="text-wrapper">
                  <h4 class="mb-0">{{ $t('modals.createPersonalizationValue.text') }}</h4>
                  <p>{{ $t('modals.createPersonalizationValue.textDesc') }}</p>
                </div>
              </div>
            </div>

            <div class="input-container">
              <input
                  id="RichTextField"
                  class="radio-button"
                  type="radio"
                  name="radio"
                  @change="onChange($event)"
              />
              <div class="radio-tile">
                <div class="icon-wrapper">
                  <b-icon icon="card-text"></b-icon>
                </div>
                <div class="text-wrapper">
                  <h4 class="mb-0">{{ $t('modals.createPersonalizationValue.richText') }}</h4>
                  <p>{{ $t('modals.createPersonalizationValue.richTextDesc') }}</p>
                </div>
              </div>
            </div>

            <div class="input-container">
              <input
                  id="MediaField"
                  class="radio-button"
                  type="radio"
                  name="radio"
                  @change="onChange($event)"
              />
              <div class="radio-tile">
                <div class="icon-wrapper">
                  <b-icon icon="card-text"></b-icon>
                </div>
                <div class="text-wrapper">
                  <h4 class="mb-0">{{ $t('modals.createPersonalizationValue.media') }}</h4>
                  <p>{{ $t('modals.createPersonalizationValue.mediaDesc') }}</p>
                </div>
              </div>
            </div>

            <div class="input-container">
              <input
                  id="NumberField"
                  class="radio-button"
                  type="radio"
                  name="radio"
                  @change="onChange($event)"
              />
              <div class="radio-tile">
                <div class="icon-wrapper">
                  <b-icon icon="card-text"></b-icon>
                </div>
                <div class="text-wrapper">
                  <h4 class="mb-0">{{ $t('modals.createPersonalizationValue.numbers') }}</h4>
                  <p>{{ $t('modals.createPersonalizationValue.numbersDesc') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </template>

    <template #modal-footer>
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="closeModal"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          :disabled="!isValidated"
          variant="primary"
          class="p-3"
          block
          @click="createValue"
      >
        {{ $t('modals.createPersonalizationValue.validate') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import slugify from '@sindresorhus/slugify';

export default {
  name: 'ModalCreateNewValue',
  props: {
    setId: {
      type: Number,
      required: true,
    },
    schema: {
      type: Array,
    },
  },
  data: () => ({
    name: null,
    id: null,
    type: null,
    idChangedManually: false,
    valueIds: [],
  }),
  computed: {
    isValidated() {
      return (this.name && this.id && this.type);
    },
  },
  created() {
    if (this.schema) {
      this.valueIds = this.schema.map((valueObject) => {
        if (valueObject.id) {
          return valueObject.id;
        }
        return false;
      }).filter((value) => value);
    }
  },
  mounted() {
    this.$refs['modal-create-value'].show();
  },
  methods: {
    createValue() {
      if (this.name && this.id && this.type) {
        this.$emit('create-value', {
          name: this.name,
          id: this.id,
          type: this.type,
        });
        this.$refs['modal-create-value'].hide();
      }
    },
    enterPressed() {
      if (this.name && this.id && this.type) {
        this.createValue();
      }
    },
    idChangedByUser(id) {
      this.idChangedManually = true;
      this.id = slugify(id);
      this.id = slugify(this.checkForDuplicateSlugs());
    },
    nameUpdated() {
      if (!this.idChangedManually) {
        this.id = slugify(this.name.toLowerCase());
        this.id = slugify(this.checkForDuplicateSlugs());
      }
    },
    checkForDuplicateSlugs() {
      // check if the slug is already in use
      if (this.valueIds.includes(this.id)) {
        let newSlug;
        // find the right postfix
        for (let i = 1; i < 100; i++) {
          newSlug = `${this.id}-${i}`;
          if (!this.valueIds.includes(newSlug)) {
            break;
          }
        }
        return newSlug;
      }
      return this.id;
    },
    onChange(event) {
      this.type = event.target.id;
    },
    closeModal() {
      this.$refs['modal-create-value'].hide();
      this.$emit('close-create-value');
    },
  },
};
</script>

<style scoped lang="scss">
$primary-color: #9A9DA0;

button[disabled="disabled"] {
  cursor: not-allowed;
}

.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .input-container {
    position: relative;
    height: 78px;
    width: calc(50% - .5rem);
    margin: 0.5rem;
    &:nth-child(even) {
      margin-right: 0;
    }
    &:nth-child(odd) {
      margin-left: 0;
    }
    h4 {
      color: #9A9DA0;
      font-size: 15px;
      font-weight: 600;
    }
    p {
      color: #9A9DA0;
      font-size: 13px;
    }

    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    //default tile styles
    .radio-tile {
      width: 100%;
      height: 100%;
      border: 1px solid #D0D4D8;
      padding: 10px 15px;
      .icon-wrapper {
        position: relative;
        float: left;
        top: -3px;
      }
      .text-wrapper {
        padding-left: 25px;
      }
    }

    //active tile styles
    .radio-button:checked + .radio-tile {
      background-color: $primary-color;
      border: 2px solid $primary-color;
      color: white;

      .icon svg {
        fill: white;
        background-color: $primary-color;
      }
      h4, p {
        color: white;
        background-color: $primary-color;
      }
    }
  }
}
</style>
