var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "modal-create-value",
    attrs: {
      id: "modal-create-value",
      "footer-class": "flex-nowrap",
      "no-close-on-backdrop": "",
      centered: ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function() {
          return [
            _c("h3", [
              _vm._v(_vm._s(_vm.$t("modals.createPersonalizationValue.title")))
            ])
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("div", [
              _c(
                "div",
                { staticClass: "mt-4 mx-5 mb-3" },
                [
                  _c("label", { attrs: { for: "value-name" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("modals.createPersonalizationValue.name")
                        ) +
                        " "
                    )
                  ]),
                  _c("b-form-input", {
                    attrs: { id: "value-name", autofocus: "", debounce: "500" },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.enterPressed.apply(null, arguments)
                      },
                      update: _vm.nameUpdated
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mx-5 mb-4" },
                [
                  _c("label", { attrs: { for: "value-id" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modals.createPersonalizationValue.id")) +
                        " "
                    )
                  ]),
                  _c("b-form-input", {
                    attrs: { id: "value-id", debounce: "500" },
                    on: {
                      input: _vm.idChangedByUser,
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.enterPressed.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.id,
                      callback: function($$v) {
                        _vm.id = $$v
                      },
                      expression: "id"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "mx-5 mb-4" }, [
                _c("div", { staticClass: "radio-tile-group" }, [
                  _c("div", { staticClass: "input-container" }, [
                    _c("input", {
                      staticClass: "radio-button",
                      attrs: { id: "TextField", type: "radio", name: "radio" },
                      on: {
                        change: function($event) {
                          return _vm.onChange($event)
                        }
                      }
                    }),
                    _c("div", { staticClass: "radio-tile" }, [
                      _c(
                        "div",
                        { staticClass: "icon-wrapper" },
                        [_c("b-icon", { attrs: { icon: "card-text" } })],
                        1
                      ),
                      _c("div", { staticClass: "text-wrapper" }, [
                        _c("h4", { staticClass: "mb-0" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("modals.createPersonalizationValue.text")
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "modals.createPersonalizationValue.textDesc"
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("input", {
                      staticClass: "radio-button",
                      attrs: {
                        id: "RichTextField",
                        type: "radio",
                        name: "radio"
                      },
                      on: {
                        change: function($event) {
                          return _vm.onChange($event)
                        }
                      }
                    }),
                    _c("div", { staticClass: "radio-tile" }, [
                      _c(
                        "div",
                        { staticClass: "icon-wrapper" },
                        [_c("b-icon", { attrs: { icon: "card-text" } })],
                        1
                      ),
                      _c("div", { staticClass: "text-wrapper" }, [
                        _c("h4", { staticClass: "mb-0" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "modals.createPersonalizationValue.richText"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "modals.createPersonalizationValue.richTextDesc"
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("input", {
                      staticClass: "radio-button",
                      attrs: { id: "MediaField", type: "radio", name: "radio" },
                      on: {
                        change: function($event) {
                          return _vm.onChange($event)
                        }
                      }
                    }),
                    _c("div", { staticClass: "radio-tile" }, [
                      _c(
                        "div",
                        { staticClass: "icon-wrapper" },
                        [_c("b-icon", { attrs: { icon: "card-text" } })],
                        1
                      ),
                      _c("div", { staticClass: "text-wrapper" }, [
                        _c("h4", { staticClass: "mb-0" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("modals.createPersonalizationValue.media")
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "modals.createPersonalizationValue.mediaDesc"
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("input", {
                      staticClass: "radio-button",
                      attrs: {
                        id: "NumberField",
                        type: "radio",
                        name: "radio"
                      },
                      on: {
                        change: function($event) {
                          return _vm.onChange($event)
                        }
                      }
                    }),
                    _c("div", { staticClass: "radio-tile" }, [
                      _c(
                        "div",
                        { staticClass: "icon-wrapper" },
                        [_c("b-icon", { attrs: { icon: "card-text" } })],
                        1
                      ),
                      _c("div", { staticClass: "text-wrapper" }, [
                        _c("h4", { staticClass: "mb-0" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "modals.createPersonalizationValue.numbers"
                              )
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "modals.createPersonalizationValue.numbersDesc"
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            _c(
              "b-button",
              {
                staticClass: "p-3 border-right-light-grey",
                attrs: { block: "" },
                on: { click: _vm.closeModal }
              },
              [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "p-3",
                attrs: {
                  disabled: !_vm.isValidated,
                  variant: "primary",
                  block: ""
                },
                on: { click: _vm.createValue }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("modals.createPersonalizationValue.validate")
                    ) +
                    " "
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }